import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import Article, { Text, Title } from '../../components/Article'
import Image from '../../components/Image'
import Button from '../../components/Button'
import Link from '../../components/Link'
import Section from '../../components/Section'

import ContactCard from '../../components/ContactCard'

import imageLogo from '../../assets/brandbook/images/logo_symbol.svg'
import imageLogo2 from '../../assets/brandbook/images/logo_full.svg'
import imageLogo3 from '../../assets/brandbook/images/logo_symbol_colours.svg'
import imageLogo4 from '../../assets/brandbook/images/logo_symbol_spacing.svg'
import imageLogo5 from '../../assets/brandbook/images/logo_full_spacing.svg'

export default function Logotype ({ data: { hero, ada }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="Logotype" />

      <Article>
        <Text>
          The NCL logotype is one of the most recognizable visual elements in
          our toolset. The following examples describes how to use the logo to
          represent NCL in the most powerful and engaging way possible.
        </Text>
        <Title level="2">The building blocks</Title>
        <Text>
          We use the the letters N, C and L in a slightly tilted version as our
          symbol. It's been hand-drawn to harmonize with our primary font,{' '}
          <Link to="brandbook/typography">Wigrum</Link>. The wordmark is set in
          Wigrum Bold Italic and reinforces the symbol when needed.
        </Text>

        <Title level="3">Logo (symbol)</Title>

        <Image src={imageLogo} />
        <Title level="3">Full logo (symbol + wordmark)</Title>

        <Image src={imageLogo2} />

        <br />

        <Title level="2">Usage</Title>

        <Text>
          In our own channels where one could expect that people already know
          who we are, we only use the symbol logo version. This also applies for
          smaller devices and screens where space is an issue. In all other
          settings we use the full logo version with both symbol and wordmark.
        </Text>
        <Image src={imageLogo3} />
        <br />
        <Text>
          Which color version you decide to use is all about context. Use the
          positive logo version on darker backgrounds and use the negative one
          on lighter backgrounds.
        </Text>
        <Text>
          Make sure you always leave enough white space around the logo.
        </Text>
        <Image src={imageLogo4} />
        <br />
        <Image src={imageLogo5} />

        <Title>Downloads</Title>
        <Text>
          The downloadable files contains both the full and symbol logotype
          version set in NCL Green, NCL Blue, Black and White. They are all
          prepared for both print and digital use.{' '}
        </Text>
        <Button
          primary
          to="https://drive.google.com/open?id=1Tuvtn02R33a0nkrPvgAY7CrDtZe9Tse5"
        >
          Print (EPS)
        </Button>

        <Button
          primary
          to="https://drive.google.com/open?id=1TjdgSt6e2BsELvhCTkUjAg_PBXZWbA2P"
        >
          Digital (PNG)
        </Button>

        <Button
          primary
          to="https://drive.google.com/open?id=1U0duCFWNySjqD9mxJTwMLSHP-ylFbHlG"
        >
          Source files (AI)
        </Button>
      </Article>
      <Section>
        <Title>Any questions?</Title>
        <ContactCard who="ada-jakobsen" />
      </Section>
    </>
  )
}

Logotype.propTypes = {
  data: PropTypes.object.isRequired,
}

Logotype.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(
      fluid: { originalName: { regex: "/logo_on_cards.jpg/" } }
    ) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
